import React from 'react'
import { SwedishCookiesInfo } from '@accodeing/gatsby-theme-heimr'

import Layout from 'components/Layout'

const Cookies = () => (
  <Layout pageTitle='Cookies'>
    <oma-grid-row className='row'>
      <section className='cookies'>
        <SwedishCookiesInfo />
        <hr />
      </section>
    </oma-grid-row>
  </Layout>
)

export default Cookies
